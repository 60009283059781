import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MobileHeaderAccordionMain from "./MobileHeader/MobileHeaderAccordionMain";
import MobileHeaderContext from "../Context/MobileHeaderContext";
import headerData from "./data/mobileHeader.json";
import { Link } from "react-router-dom";
import { apiBaseUrl, baseUrl } from "../config";
import { useDispatch, useSelector } from "react-redux";
import {
  setAllCourseList,
  setSocialList,
  setSpecializationList,
} from "../reducer/headerSlice";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  const [searchList, setSearchList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const allcourse = useSelector((state) => state.header?.allCourseList);
  const specializationList = useSelector((state) => state.header?.specializationList);
  const sociallist = useSelector((state) => state.header?.socialList);
  const [currentAllTab, setCurrentAllTab] = useState("allCLi0");
  const [mainCatName, setMainCatName] = useState(allcourse[0]?.main_course_name);
  useEffect(() => {if (allcourse) {setMainCatName(allcourse[0]?.main_course_name)}}, [allcourse])
  const [currentSpecializationTab, setCurrentSpecializationTab] = useState("splLi0");
  const [scrolled, setScrolled] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [accordionContent, setAccordionContent] = useState([]);
  const [currentAccordion, setCurrentAccordion] = useState("");
  const [AccordionArr, setAccordionArr] = useState([""]);
  const [searchQuery, setSearchQuery] = useState();
  const [specializationsubmenu, setSpecializationsubmenu] = useState([]);
  const [generalCourseCategoryId, setgeneralCourseCategoryId] = useState([])
  const [activeBg, setActiveBg] = useState(false);
  const [isUpdated, setIsUpdated] = useState(false);

  useEffect(() => {
    if (specializationList && specializationList.length > 0 && !isUpdated) {
      const mobsepialization = specializationList.map((item) => item.name);
      if (mobsepialization.length > 0) {
        const specializationSection = headerData.find(section => section.name === 'Specialization');
        
        if (specializationSection) {
          const existingNames = specializationSection.items.map(item => item.name);
          const newItems = mobsepialization.filter(name => !existingNames.includes(name));

          if (newItems.length > 0) {
            specializationSection.items.push(...newItems.map(name => ({ name, items:[
              
              { name: name, 
                link: `/general-course/${name}` 
              }
            ] })));
            setIsUpdated(true);
          }
        }
      }
    }
  }, [specializationList, isUpdated]);

  useEffect(() => {
    if (allcourse && allcourse.length > 0 && !isUpdated.current) {
      const moballcourse = allcourse.map((item) => item.main_course_name);

      if (moballcourse.length > 0) {
        const allcourseSection = headerData.find(section => section.name === 'All Courses');

        if (allcourseSection) {
          const existingNames = allcourseSection.items.map(item => item.name);

          // Filter out items that are already present in the list
          const newItems = moballcourse.filter(name => !existingNames.includes(name));

          if (newItems.length > 0) {
            allcourseSection.items.push(...newItems.map(name => ({ name })));
            setIsUpdated(true);
          }
        }
      }
    }
  }, [allcourse]);

  const handleScroll = () => {
    if (window.scrollY > 100) {
      setActiveBg(true);
    } else {
      setActiveBg(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (specializationList && specializationList.length > 0) {
      setCurrentSpecializationTab(specializationList[0].id);
    }
  }, [specializationList]);

  useEffect(() => {
    if (AccordionArr.length === 1) {
      setCurrentAccordion("");
    }
  }, []);

  useEffect(() => {
    if (accordionContent) {
      setAccordionContent(accordionContent);
    } else {
      setAccordionContent(headerData);
    }
  }, []);

  const fetchAllCourse = () => {
    fetch(`${apiBaseUrl}all-courses`)
      .then((res) => res.json())
      .then((data) => dispatch(setAllCourseList(data)))
      .catch((err) => console.error(err));
  };

  const fetchSpecialization = () => {
    fetch(`${apiBaseUrl}couse-specialization`)
      .then((res) => res.json())
      .then((data) => {
        dispatch(setSpecializationList(data));
        setgeneralCourseCategoryId(data?.map(item => item.id));
        data?.forEach(item => {
          fetchSpecializationsubmenu(item.id);
        });
      })
      .catch((err) => console.error(err));
  };


  const fetchSpecializationsubmenu = (categoryId) => {
    fetch(`${apiBaseUrl}couse-specialization-submenu/${categoryId}`)
      .then((res) => res.json())
      .then((data) => {
        setSpecializationsubmenu(prevState => ({
          ...prevState,
          [categoryId]: data
        }));
      })
      .catch((err) => console.error("Error:", err));
  };


  const fetchSocialList = () => {
    fetch(`${apiBaseUrl}social-media-list`)
      .then((res) => res.json())
      .then((data) => {
        if (data.status === "success") {
          dispatch(setSocialList(data));
        }
      })
      .catch((err) => console.error(err));
  };

  useEffect(() => {
    fetchSpecializationsubmenu()
  }, [generalCourseCategoryId])

  useEffect(() => {
    fetchAllCourse();
    fetchSpecialization();
    fetchSocialList();
  }, []);

  function BackMenu(index) {
    const newArr = [...AccordionArr];
    newArr.splice(index, 1);
    setAccordionArr(newArr);
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const location = useLocation();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function handleSearchClick(type, keyword) {
    if (type === "univercity") {
      navigate(`/university/${keyword}`);
    }
    if (type === "course") { 
      navigate(`/general-course/${keyword}`);
    }
    if (type === "specialization") {
      navigate(`/general-specialization-course/${keyword}`)
    }
  }

  const handleSearch = (e) => {
    e.preventDefault();
  }

  // useEffect(() => {
  //   const getData = setTimeout(() => {
  //     fetch(`${apiBaseUrl}search-auto-suggest/${searchValue}`)
  //       .then((res) => res.json())
  //       .then((data) => {
  //         if (data) {
  //           setSearchList(data);
  //         }
  //       }).catch((err) => console.error(err));
  //   }, 1000);
  //   return () => clearTimeout(getData);
  // }, [searchValue]);

  const submenuhandleClick = (name) => {
    navigate(`/general-course/${name}`); 
    window.location.reload(); 
  };

  const programcatagoryhandleClick = (name) => {
    navigate(`/general-specialization-course/${name}`); 
    window.location.reload(); 
  };

  const allcoursehandleClick = (name) => {
    navigate(`/university/${name}`); 
    window.location.reload(); 
  };


  return (
    <MobileHeaderContext.Provider
      value={{
        setAccordionContent,
        accordionContent,
        currentAccordion,
        setCurrentAccordion,
        AccordionArr,
        setAccordionArr,
      }}
    >
      <header
        className={location.pathname === "/" ? "header" : "header no-fixed"}
      >
        {/* Top Bar */}
        <div className="top_bar">
          <div className="top_bar_container">
            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="top_bar_content d-flex flex-row align-items-center justify-content-start">
                    <ul className="top_bar_contact_list d-inline-flex align-items-center">
                      <li>
                        <Link
                          className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                          to={"tel:" + sociallist?.get_started_number}
                        >
                          <i className="fa fa-phone" aria-hidden="true" />
                        </Link>
                      </li>
                      <li>
                        <Link
                          className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                          to="mailto:info@unversityguroo.com"
                        >
                          <i className="fa fa-envelope-o" aria-hidden="true" />
                        </Link>
                      </li>
                    </ul>
                    <div className="top_bar_login ml-auto">
                      <div
                        className="d-inline-flex align-items-center"
                        style={{ height: 45 }}
                      >
                        <Link
                          className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                          to={
                            sociallist?.social_list?.find(
                              (item) => item.title === "Facebook"
                            ).social_link
                          }
                        >
                          <i className="uil uil-facebook-f" />
                        </Link>
                        <Link
                          className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                          to={
                            sociallist?.social_list?.find(
                              (item) => item.title === "Instagram"
                            ).social_link
                          }
                        >
                          <i className="uil uil-instagram" />
                        </Link>
                        <Link
                          className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                          to={
                            sociallist?.social_list?.find(
                              (item) => item.title === "X"
                            ).social_link
                          }
                        >
                          <i className="uil uil-twitter-alt" />
                        </Link>
                        <Link
                          className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2"
                          to={
                            sociallist?.social_list?.find(
                              (item) => item.title === "Linkedin"
                            ).social_link
                          }
                        >
                          <i className="uil uil-linkedin-alt" />
                        </Link>
                        <Link
                          className="btn btn-sm btn-outline-light btn-sm-square rounded-circle"
                          to={
                            sociallist?.social_list?.find(
                              (item) => item.title === "Youtube"
                            ).social_link
                          }
                        >
                          <i className="uil uil-youtube" />
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Header Content */}
        <div className={`header_container ${activeBg ? "activebg" : ""}`}>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="header_content d-flex flex-row align-items-center justify-content-between">
                  <div className="logo_container">
                    <Link className to="/">
                      <div className="logo_text">
                        <img src="/image/logo.png" alt="Universityguroo" />
                      </div>
                    </Link>
                    <div className="logotagline">
                      <p>OnlineEducationSimplified</p>
                    </div>
                  </div>
                  {/* To activate Navbar-mobile MENU */}
                  <div className="menubtn bg-dark my-4 d-md-none d-block">
                    <div className="container">
                      <div className="text-right py-2">
                        <button
                          onClick={() => {
                            toggleMenu();
                            setAccordionContent(headerData);
                          }}
                          className="btn btn-secondary rounded-0"
                        >
                          <i className="fa fa-bars"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  {/* Navbar - Mobile */}
                  {isMenuOpen && (
                    <div id="navbar-mobile" className={`bg-light`}>
                      <div className="container">
                        <div className="text-right mt-4">
                          <button
                            onClick={() => {
                              toggleMenu();
                              setAccordionContent([]);
                              setAccordionArr([""]);
                              setCurrentAccordion("");
                            }}
                            className="btn btn-danger rounded-0"
                          >
                            Close X
                          </button>
                        </div>

                        {accordionContent.length > 0 && (
                          <div style={{ position: "relative" }}>
                            {/* <MobileHeaderAccordionMain content={accordionContent}/> */}
                            {AccordionArr &&
                              AccordionArr.map((item, index) => (
                                <div
                                  key={index}
                                  style={{
                                    position:
                                      index === 0 ? "absolute" : "fixed",
                                    left: "0px",
                                    padding: "20px",
                                    top: "0px",
                                    width: "100%",
                                    height: "100vh",
                                    backgroundColor: "#fff",
                                  }}
                                >
                                  {index > 0 && (
                                    <>
                                      <div
                                        style={{
                                          width: "31px",
                                          height: "38px",
                                          boxShadow:
                                            "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
                                          display: "flex",
                                          justifyContent: "center",
                                          alignItems: "center",
                                          borderRadius: "4px",
                                        }}
                                        onClick={() => BackMenu(index)}
                                      >
                                        <i className="fa-solid fa-arrow-left-long"></i>
                                      </div>
                                    </>
                                  )}
                                  <div>
                                    <MobileHeaderAccordionMain
                                      acc={currentAccordion}
                                      content={
                                        accordionContent && accordionContent
                                      }
                                    />
                                  </div>
                                </div>
                              ))}
                          </div>
                        )}
                      </div>
                    </div>
                  )}

                  {/* ---------------------------------------------------------------------------------------------- */}
                  {/* Navbar - Desktop */}
                  <div id="navbar" className="my-4 d-md-block d-none">
                    <div className="container">
                      <div className="d-flex justify-content-right align-items-center">
                        {/*--Explore Menu Start----*/}
                        <div className="menu-item">
                          <Link to="#" className="menu-link">
                            Specialization <i className="fa fa-caret-down" />
                          </Link>
                          <div className="menu-content">
                            <div className="menuwrap container">
                              <div className="row">
                                <div className="col-md-3">
                                  <div
                                    className="list-group rounded-0"
                                    id="development-tabs"
                                  >
                                    {specializationList?.map((item, index) => (
                                      <li
                                        key={"splLi" + index}
                                        id={"splLi" + item.id}
                                        onClick={() => setCurrentSpecializationTab(item.id)}
                                        className={
                                          currentSpecializationTab === item.id
                                            ? "list-group-item list-group-item-action active"
                                            : "list-group-item list-group-item-action"
                                        }
                                      >
                                        <b>{item.name}</b>
                                      </li>
                                    ))}
                                  </div>
                                  {/* #development-tabs .list-group */}
                                </div>
                                {/* col-md-4 */}
                                <div className="col-md-9 bg-light">
                                  <div className="tab-content">
                                    {specializationsubmenu &&
                                      Object.values(specializationsubmenu).map((submenu, index) => {
                                        const filteredSubmenu = submenu.filter(
                                          (item) => item.name && item.name
                                            .toLowerCase()
                                            .includes(searchQuery?.toLowerCase() || "")
                                        );

                                        return (
                                          <div
                                            key={"specialTContent" + index}
                                            id={"specialTContent" + submenu[0]?.general_course_category_id}
                                            className={
                                              currentSpecializationTab === submenu[0]?.general_course_category_id
                                                ? "tab-pane fade show active p-1"
                                                : "tab-pane fade p-1"
                                            }
                                          >
                                            <div className="program-wrap">
                                              <div className="university-prog">
                                                <div className="menu-sub-fixbar">
                                                  <h4 className="text-left mb-2">University Programs</h4>
                                                  <form onSubmit={handleSearch}>
                                                    <div className="d-flex justify-content-center h-100">
                                                      <div className="search w-100">
                                                        <input
                                                          className="search_input"
                                                          type="text"
                                                          value={searchQuery}
                                                          onChange={(e) => setSearchQuery(e.target.value)}
                                                          name="searchbyname"
                                                          placeholder="Search here..."
                                                        />
                                                        <button type="submit" className="search_icon">
                                                          <i className="fa fa-search" />
                                                        </button>
                                                      </div>
                                                    </div>
                                                  </form>
                                                </div>
                                                <div className="uni-course row-cols-4 g-3 row">
                                                  {filteredSubmenu.map((item, subIndex) => (
                                                    <Link
                                                      key={subIndex}
                                                      className="col text-dark menu-content-link"
                                                      onClick={() => submenuhandleClick(item.name)}
                                                    >
                                                      <div className="unis-col d-flex">
                                                        <div className="unis-course-img border-right pr-2 d-flex align-items-center">
                                                          <img
                                                            className="spec-tab-content-img"
                                                            src={`https://universityguroo.com/images/644117d270704amrita.jpg`}
                                                            alt="Universityguroo"
                                                          />
                                                        </div>
                                                        <div className="unis-program spec-menu">
                                                          <p className="corse-title">{item.name}</p>
                                                          <div className="btn-course btn">
                                                            Discover More <i className="uil uil-angle-right" />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Link>
                                                  ))}
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      })}
                                  </div>
                                  {/* tab-content */}
                                </div>
                                {/* .col-md-8 */}
                              </div>
                              {/* /.row */}
                            </div>
                            {/* menu-content > .container */}
                          </div>
                          {/* menu-content */}
                        </div>
                        {/*--Explore Menu End----*/}
                        {/*--All Courses MEnu Starty Here----*/}
                        <div className="menu-item">
                          <Link to="#" className="menu-link">
                            All Courses <i className="fa fa-caret-down" />
                          </Link>
                          <div className="menu-content">
                            <div className="menuwrap container">
                              <div className="row">
                                <div className="col-md-3">
                                  <div
                                    className="list-group rounded-0"
                                    id="development-tabs2"
                                  >
                                    {allcourse?.map((item, index) => (
                                      <li
                                        key={"allCLi" + index}
                                        id={"allCLi" + index}
                                        onClick={() => {
                                          setCurrentAllTab("allCLi" + index);
                                          setMainCatName(item.main_course_name);
                                        }}
                                        // data-bs-target="#online1-tab-content"
                                        data-toggle="tab"
                                        className={
                                          currentAllTab === "allCLi" + index
                                            ? "list-group-item list-group-item-action active"
                                            : "list-group-item list-group-item-action "
                                        }
                                      >
                                        <b>{item.main_course_name}</b>
                                      </li>
                                    ))}

                                    {/* /.list-group-item */}
                                  </div>
                                  {/* #development-tabs .list-group */}
                                </div>
                                {/* col-md-4 */}
                                <div className="col-md-9 bg-light">
                                  <div className="tab-content">
                                    {allcourse?.map((item, index) => (
                                      <div
                                        key={"allCTContent" + index}
                                        id={"allCTContent" + index}
                                        className={
                                          currentAllTab === "allCLi" + index
                                            ? "tab-pane fade show active p-1"
                                            : "tab-pane fade  p-1"
                                        }
                                      >
                                        <div className="menu-sub-fixbar">
                                          <h4 className="text-left mb-2">
                                            University Programs
                                          </h4>
                                          <form onSubmit={handleSearch}>
                                            <div className="d-flex justify-content-center h-100">
                                              <div className="search w-100">
                                                <input
                                                  className="search_input"
                                                  type="text"
                                                  value={searchQuery}
                                                  onChange={(e) => setSearchQuery(e.target.value)}
                                                  name="search"
                                                  placeholder="Search here..."
                                                />
                                                <button type="submit" className="search_icon">
                                                  <i className="fa fa-search" />
                                                </button>
                                              </div>
                                            </div>
                                          </form>
                                        </div>
                                        <div className="all-crous-menu program-wrap">
                                          <div className="row">
                                            <div className="col-lg-3 all-course-sub popular-programs">
                                              <h4 className="text-left">
                                                Popular Programs
                                              </h4>
                                              <div className="program-list flex-1">
                                                {item.popular_programme.map(
                                                  (program, indx) => (
                                                    <Link
                                                      key={indx}
                                                      className="text-dark menu-content-link"
                                                      onClick={() => programcatagoryhandleClick(program.subcourse_name)}
                                                    >
                                                      {program.subcourse_name}
                                                    </Link>
                                                  )
                                                )}
                                              </div>
                                            </div>
                                            <div className="col-lg-9 all-course-prog university-prog">
                                              {/* <h4 className=text-left mb-2">University Programs</h4> */}
                                              <div className="ug-all-course row">
                                                {item.univercity_programme
                                                  .filter(university =>
                                                    university.programme_name
                                                      .toLowerCase()
                                                      .includes(searchQuery?.toLowerCase() || "")
                                                  )
                                                  .map((university, indx) => (
                                                    <Link
                                                      key={university.univercity_programme_id}
                                                      className="col-4 text-dark menu-content-link"
                                                      onClick={() => allcoursehandleClick(university?.programme_name)}
                                                    >
                                                      <div className="unis-col d-flex" key={indx}>
                                                        <div className="unis-course-img">
                                                          <img
                                                            className="uni-tab-content-img"
                                                            src={`${baseUrl}${university.image}`}
                                                            alt="Universityguroo"
                                                          />
                                                        </div>
                                                        <div className="unis-program">
                                                          <p className="corse-title">
                                                            {university.programme_name}
                                                          </p>
                                                          <div className="btn-course btn">
                                                            Discover More{" "}
                                                            <i className="uil uil-angle-right" />
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </Link>
                                                  ))}
                                              </div>
                                              {/*- Explore button -*/}
                                              <div className="explore_button d-flex justify-content-end trans_200 my-5">
                                                <Link
                                                  to={`/explore-course-category/${mainCatName}`}
                                                >
                                                  Explore The Category
                                                </Link>
                                              </div>
                                              {/*- Explore button -*/}
                                            </div>
                                          </div>
                                        </div>
                                        {/* /.d-flex */}
                                      </div>
                                    ))}
                                  </div>
                                  {/* tab-content */}
                                </div>
                                {/* .col-md-8 */}
                              </div>
                              {/* /.row */}
                            </div>
                            {/* menu-content > .container */}
                          </div>
                          {/* menu-content */}
                        </div>
                        {/* menu-item */}
                        <div className="menu-item">
                          <Link to="#" className="menu-link">
                            Refer &amp; Earn
                          </Link>
                        </div>
                        {/* menu-item */}
                        <div className="menu-item">
                          <Link to="#" className="menu-link">
                            UG Resources <i className="fa fa-caret-down" />
                          </Link>
                          <div className="menu-content menu-single">
                            <div className>
                              <div className>
                                <div className="single-menu-program">
                                  <Link
                                    to="#"
                                    className="text-dark menu-content-link"
                                  >
                                    <img src="/image/about.png" /> About Us
                                  </Link>
                                  <Link
                                    to="#"
                                    className="text-dark menu-content-link"
                                  >
                                    <img src="/image/pledge.png" /> University
                                    Guroo Pledge
                                  </Link>
                                  <Link
                                    to="#"
                                    className="text-dark menu-content-link"
                                  >
                                    <img src="/image/things.png" /> Things You
                                    Must Know
                                  </Link>
                                  <Link
                                    to="#"
                                    className="text-dark menu-content-link"
                                  >
                                    <img src="/image/why-us.png" /> Why UG?
                                  </Link>
                                  <Link
                                    to="#"
                                    className="text-dark menu-content-link"
                                  >
                                    <img src="/image/alumni.png" /> Alumni
                                    Connect
                                  </Link>
                                  <Link
                                    to="#"
                                    className="text-dark menu-content-link"
                                  >
                                    <img src="/image/collaborate.png" /> Lets
                                    Collaborate
                                  </Link>
                                  <Link
                                    to="#"
                                    className="text-dark menu-content-link"
                                  >
                                    <img src="/image/policy.png" /> Our Policy
                                  </Link>
                                  <Link
                                    to="#"
                                    className="text-dark menu-content-link"
                                  >
                                    <img src="/image/contact.png" /> Contact Us
                                  </Link>
                                  <Link
                                    to="#"
                                    className="text-dark menu-content-link"
                                  >
                                    <img src="/image/virtual-counselling.png" />{" "}
                                    Virtual Counselling
                                  </Link>
                                  <Link
                                    to="#"
                                    className="text-dark menu-content-link"
                                  >
                                    <img src="/image/terms.png" /> Terms &amp;
                                    Conditions
                                  </Link>
                                </div>
                              </div>
                              {/* /.row */}
                            </div>
                            {/* menu-content > .container */}
                          </div>
                          {/* menu-content */}
                        </div>
                        {/* menu-item */}
                        <div className="menu-item">
                          <div className="search_button" onClick={() => setShowSearch(!showSearch)}>
                            <i className="fa fa-search" aria-hidden="true" />
                          </div>
                        </div>
                        {/* menu-item */}
                      </div>
                      {/* /.d-flex justify-content-around align-items-center */}
                    </div>
                    {/* /.container */}
                  </div>
                  {/* /#navbar (desktop) */}
                  {/* BULLSHIT CONTENT ===================== */}
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Header Search Panel */}
        {/* Header Search Panel */}
        <div className={`header_search_container ${showSearch ? "active" : ""}`}>
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="header_search_content d-flex flex-row align-items-center justify-content-end">
                  <form className="header_search_form">
                    <input
                      type="search"
                      className="search_input"
                      placeholder="Search"
                      required="required"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                    />
                    <button className="header_search_button d-flex flex-column align-items-center justify-content-center">
                      <i className="fa fa-search" aria-hidden="true" />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>


        <div style={{
          width: '100%',
          position: 'absolute',
          right: '0',
          marginTop: '22px'
        }}>
          {searchList.length > 0 && searchValue !== "" && (
            <div className="suggestionBox">
              {searchList?.map((item, index) => (
                <div
                  key={index}
                  onClick={() =>
                    handleSearchClick(
                      item.type,
                      item.result_key
                    )
                  }
                  className="suggestKeyword"
                >
                  <div className="suggestSearchIcon">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </div>
                  <div className="keywordText">
                    <div className="keywordTextCont">
                      {item.result_key}
                    </div>
                    <div className="keywordTextType">
                      {item.type}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>


      </header>
    </MobileHeaderContext.Provider>
  );
};

export default React.memo(Header);
